@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.header {
  padding: 8px 0;
  box-shadow: 0px 3px 12px 2px rgba(0,0,0,0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: $background;

  @include onTablet {
    padding: 16px 0;
  }
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: none;
  height: calc(100% - 65px);
  margin-top: 65px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $background;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &--active {
    display: flex;
  }

  @include onTablet {
    height: calc(100vh - 81px);
    margin-top: 81px;
  }
  
  @include on900 {
    display: flex;
    flex-direction: row;
    position: static;
    height: auto;
    width: auto;
    margin-top: 0;
    gap: 16px;
  }
}

.item {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 0 0 24px;
  border-top: 1px solid rgba(136, 136, 156, 0.3);

  @include onTablet {
    padding: 0 0 0 32px;
  }

  @include on900 {
    flex: auto;
    padding: 0;
    border-top: none;
  }
}

.item, .item a {
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  color: #fefefe;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  width: 100%;

  &:hover {
    color: $secondary;
  }

  @include on900 {
    font-size: 16px;
    width: auto;
  }
}

.item--active, .item--active a {
  color: $secondary;
}

.menuBtn {
  color: #fefefe !important;

  @include on900 {
    display: none !important;
  }
}

