.container {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 1240px) {
    padding-left: 24px;
    padding-right: 24px;
    width: 1040px;
  }

  @media (min-width: 1900px) {
    width: 1400px;
  }
}
