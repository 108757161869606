.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination {
  position: relative;
  bottom: 0 !important;
  margin-top: 24px;
}

.swiper-pagination-bullet-active {
  background-color: #CFB07A;
}
