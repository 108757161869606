.footer {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 600px) {
    flex-direction: row;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.socials {
  display: flex;
  gap: 16px;
}

.icon {
  color: #fafafa;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #CFB07A;
  }
}
